var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[(_vm.status)?_c('div',{attrs:{"id":"data-list"}},[(_vm.userData.role === 'PLSYR' && _vm.$vuetify.breakpoint.xsOnly)?_c('customer-list'):_vm._e(),_c('v-card',{class:_vm.$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg',attrs:{"tile":"","flat":_vm.$vuetify.breakpoint.mdAndDown}},[_c('v-toolbar',{attrs:{"color":_vm.isDark ? '#312d4b' : '#fafafa',"flat":!_vm.$vuetify.breakpoint.smAndDown,"height":_vm.$vuetify.breakpoint.smAndDown ? 57 : 87}},[_c('v-toolbar-title',{staticClass:"text-body-2 text-md-h6 font-weight-medium"},[_c('v-icon',{attrs:{"large":!_vm.$vuetify.breakpoint.smAndDown,"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentEdit)+" ")]),_vm._v(" Satış Sözleşmeleri ")],1),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.dataListTable,"options":_vm.options,"items-per-page":15,"fixed-header":"","height":_vm.dataTableHeight,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.DOCTYPE",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"grey"}},[_c('span',{staticClass:"black--text"},[_vm._v(" "+_vm._s(item.DOCTYPE))])])]}},{key:"item.DOCNUM",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"grey"}},[_c('span',{staticClass:"black--text"},[_vm._v(" "+_vm._s(item.DOCNUM))])])]}},{key:"item.CUSTOMER",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"grey"}},[_c('span',{staticClass:"black--text"},[_vm._v(" "+_vm._s(item.CUSTOMER))])])]}},{key:"item.NAME1",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"grey"}},[_c('span',{staticClass:"black--text"},[_vm._v(" "+_vm._s(item.NAME1))])])]}},{key:"item.ITEMNUM",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"grey"}},[_c('span',{staticClass:"black--text"},[_vm._v(" "+_vm._s(item.ITEMNUM))])])]}},{key:"item.MTEXT",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"grey"}},[_c('span',{staticClass:"black--text"},[_vm._v(" "+_vm._s(item.MTEXT))])])]}},{key:"item.COMPONENT",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"grey"}},[_c('span',{staticClass:"black--text"},[_vm._v(" "+_vm._s(item.COMPONENT))])])]}},{key:"item.TUK1QUANTITY",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"success--text text-caption font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.TUK1QUANTITY)))])]}},{key:"item.TUK2QUANTITY",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"grey"}},[_c('span',{staticClass:"error--text text-caption font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.TUK2QUANTITY)))])])]}},{key:"item.TUK3QUANTITY",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"grey"}},[_c('span',{staticClass:"error--text text-caption font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.TUK3QUANTITY)))])])]}},{key:"item.TUK1STOCK",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"grey"}},[_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.TUK1STOCK)))])])]}},{key:"item.TUK2STOCK",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"grey"}},[_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.TUK2STOCK)))])])]}},{key:"item.TUKKORDER",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"grey"}},[_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.TUKKORDER)))])])]}},{key:"item.TUKMORDER",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"grey"}},[_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.TUKMORDER)))])])]}},{key:"item.TUTAR",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"grey"}},[_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(item.TUTAR))])])]}},{key:"item.CURRENCY",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"grey"}},[_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(item.CURRENCY))])])]}},{key:"item.CREATEDAT",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text--secondary text-caption"},[_vm._v(" "+_vm._s(item.CREATEDAT.substring(0, 16)))])]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1):_c('div',[(_vm.userData.role === 'PLSYR' && _vm.$vuetify.breakpoint.xsOnly)?_c('customer-list'):_vm._e(),_c('v-alert',{staticClass:"mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption",attrs:{"text":"","outlined":"","type":"warning"}},[_vm._v(" Lütfen yukarıdan bir müşteri seçin ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }